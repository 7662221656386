import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"

const NotFoundPage = () => (
  <Layout showTitle={true}>
    <SEO title="404: Not found"/>
    <div style={{ textAlign: "center" }}>
      <h1>404 :(</h1>
      <h2>PAGE NOT FOUND</h2>
      <p>You just hit a page that does not exist...</p>
      <p>Try <a style={{ textDecoration: "underline" }} href="/">starting from the main page</a>
      </p>
    </div>
  </Layout>
)

export default NotFoundPage
